import React from 'react';
import SliderCarousel from './SliderCarousel';
import logo from '../images/logo.jpg';
import pointIcon  from '../images/point.svg';
import accountIcon  from '../images/account.svg';
import joinIcon  from '../images/join.svg';
import 'bootstrap/dist/css/bootstrap.min.css';


const ACTION_TYPE = {
  'joinChannel': 'Join Channel',
  'joinChat': 'Join Chat Telegram',
  'joinBetaGame': 'Join Beta Game',
  // 'joinMarket': 'Visit MarketPlace',
  // 'joinGame': 'Visit Cwgame.io',
  'joinWhitePaper': 'Visit WhitePaper',
  'followX': 'Follow X',
  'followTiktok': 'Follow Tiktok',
  'followYoutube': 'Follow Youtube',
  'followBinance': 'Follow Square Binance',
  'registerBinance': 'Register Binance',
  'registerOkx': 'Register OKX',
  'registerBitget': 'Register Bitget'
}

const Point = ({point}) => {
  return (
    <span className='point-number'>+ {point} <img src={logo} alt=""/></span>
  );
}

const Home = ({ user }) => {
  return (
    <div className='home-page'>
      <h2>WELCOME TO</h2>
      <SliderCarousel userId={user.userId} />
      <div className='user-point'>
        <div className='point'><img src={pointIcon} alt=""/>{user.totalPoints || 0} POINTS!</div>
        <div className='logo'><img src={logo} alt=""/></div>
      </div>
      <h4 className="reward-title">YOUR REWARDS</h4>
      <div className="reward-item account-age">
        <div className='left-item'>
          <span className="icon">
            <img src={accountIcon} alt=''/>
          </span>
          <span className="title">Account age</span>
        </div>
        <div className='right-item'>{user.accountAge ?  (<Point point={user.accountAge} />) : 0}</div>
      </div>
      <div className="reward-item premium">
        <div className='left-item'>
          <span className="icon">
            <img src={accountIcon} alt=''/>
          </span>
          <span className="title">Telegram Premium</span>
        </div>
        <div className='right-item'>{user.isPremium ?  (<Point point={300} />) : 0}</div>
      </div>
      <div className="reward-item invite-friend">
        <div className='left-item'>
          <span className="icon">
            <img src={accountIcon} alt=''/>
          </span>
          <span className="title">Invited friends</span>
        </div>
        <div className='right-item'>{user.referralPoint ?  (<Point point={user.referralPoint} />) : 0}</div>
      </div>
      {Object.keys(ACTION_TYPE).map((type, index) => (
        <div className="reward-item premium" key={index}>
          <div className='left-item'>
            <span className="icon">
              <img src={joinIcon} alt=''/>
            </span>
            <span className="title">{ACTION_TYPE[type]}</span>
          </div>
          <div className='right-item'>{user.hasOwnProperty(type) ?  (<Point point={user[type]} />) : 0}</div>
        </div>
      ))}
    </div>
  );
};

export default Home;
