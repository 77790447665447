import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from './constant';
import Home from './components/Home';
import LeaderBoard from './components/LeaderBoard';
import Friends from './components/Friends';
import Mission from './components/Mission';
import Loading from './components/Loading';
import homeIcon  from './images/home.svg';
import homeActiveIcon  from './images/home-active.svg';
import leaderboardIcon  from './images/account.svg';
import leaderActiveIcon  from './images/account-active.svg';
import friendIcon  from './images/friend.svg';
import friendActiveIcon  from './images/friend-active.svg';
import missionIcon  from './images/mission.svg';
import missionActiveIcon  from './images/mission-active.svg';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('home');
  const [user, setUser] = useState([]);
  const [friends, setFriends] = useState([]);
  const [userCount, setUserCount] = useState(0);

  const fetchFriends = () => {
    try {
        const {initDataUnsafe: { user: {id: userId = "id" } = {}} = {}} = window.Telegram.WebApp || {};
        axios.get(`${baseUrl}/friends/${userId}`).then( res => {
          const {data: { friends = [] } = {}} = res || {};
          setFriends(friends || []);
        });
    } catch (error) {
        console.error('Error fetching invited friends', error);
    }
  };

  const fetchHolders = () => {
    try {
        axios.get(`${baseUrl}/holders`).then( res => {
          const {data: { count = 0 } = {}} = res || {};
          setUserCount(count);
        });
    } catch (error) {
        console.error('Error fetching holders', error);
    }
  };
  
  const fetchUsers = async () => {
    try {
        const {initDataUnsafe: { user = {}, start_param = ""} = {}} = window.Telegram.WebApp || {};
        // user.userId = "5428659614"; // X6KEi2X8hDhXN0ubpBfH
        // user.userId = "1721414494"; // henryfcv
        // user.userId = "1692396344"; // CherryHMH
        // user.userId = "6122654642";
        // const start_param = "5d8b8b6dd157ff28fae0d6";
        // const user = {
        //   "id": 7131662172,
        //   "first_name": "Bui",
        //   "last_name": "Le Quang",
        //   "username": "qbui262",
        // };


        await axios.post(`${baseUrl}/startApp`, {
          "user": user,
          "referrerCode": start_param
        });
        const response = await axios.get(`${baseUrl}/user/${user.id}`);
        const { data = {} } = response || {};
        setUser(data);
        setLoading(false);
    } catch (error) {
        console.error('Error fetching joined users:', error);
    }
  };

  useEffect(() => {
      fetchHolders();
      fetchUsers();
      fetchFriends();
  }, []);

  return (
    <div className="cricket-app">
      {
        loading ? ( <Loading loadingText={"Calculating"} /> ) : (
          <div>
            <div className='app-container'>
              {activeTab === 'home' && <Home user={user} />}
              {activeTab === 'leaderboard' && <LeaderBoard user={user} userCount={userCount} />}
              {activeTab === 'friends' && <Friends code={user.referralCode} userId={user.userId} friends={friends} />}
              {activeTab === 'mission' && <Mission userId={user.userId} address={user.walletAddress || ""} />}
            </div>
            <nav>
              <ul>
                <li className={activeTab === 'home' ? 'active-tab': ''} onClick={() => setActiveTab('home')} style={{paddingTop: '3px'}}>
                  <img src={activeTab === 'home' ? homeActiveIcon : homeIcon} alt=''/>
                  <div className='btn-nav'>Home</div>
                </li>
                <li className={activeTab === 'leaderboard' ? 'active-tab': ''} onClick={() => setActiveTab('leaderboard')}>
                  <img src={activeTab === 'leaderboard' ? leaderActiveIcon : leaderboardIcon} alt=''/>
                  <div className='btn-nav'>Leaderboard</div>
                </li>
                <li className={activeTab === 'friends' ? 'active-tab': ''} onClick={() => setActiveTab('friends')}>
                   <img src={activeTab === 'friends' ? friendActiveIcon : friendIcon} alt=''/>
                  <div className='btn-nav'>Friends</div>
                </li>
                <li className={activeTab === 'mission' ? 'active-tab': ''} onClick={() => setActiveTab('mission')} style={{paddingTop: '3px'}}>
                   <img src={activeTab === 'mission' ? missionActiveIcon : missionIcon} alt=''/>
                  <div className='btn-nav'>Mission</div>
                </li>
              </ul>
            </nav>
        </div>
        )
      }
    </div>
  );
}

export default App;
