// src/Loading.js
import React from 'react';
import '../css/Loading.css';

const Loading = ({ loadingText }) => {
  return (
    <div className="loading">
      <div className="spinner"></div>
      <p>{loadingText}...</p>
    </div>
  );
};

export default Loading;
